'use strict';
(function() {
  if (IS_DEVELOPMENT) return;

  var errorHandler = function(event) {
    var message = 'An unexpected error has occurred.'
    message = message + ' Please update your browser to the latest version or try using a different browser.'
    message = message + ' If the problem persists, copy this message and contact our support team for assistance.\n\n';

    if (event) {
      message = message + 'Error message: ' + event.message + '\n';
      message = message + 'filename: ' + event.filename + '\n';
      message = message + 'lineno: ' + event.lineno + '\n';
      message = message + 'colno: ' + event.colno + '\n';

      if (event.error) {
        message = message + 'Stack: ' + event.error.stack;
      }
    }

    var body = document.body;
    var pre = document.createElement('pre');
    pre.style.textWrap = 'wrap';
    var messageNode = document.createTextNode(message);
    pre.appendChild(messageNode)
    body.replaceChildren(pre);
  };

  window.addEventListener('error', errorHandler);

  document.addEventListener(
    'DOMContentLoaded',
    function() {window.removeEventListener('error', errorHandler)},
    {once: true}
  );
})()

